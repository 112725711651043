import {defineStore} from "pinia";
import type {ApiResponse} from "~/types/ApiResponse";
import type {CityAirport} from "~/types/flight/city/CityAirport";

export const useAirportStore = defineStore('flightAirport', {
    state: () => ({
        data: <CityAirport[]>[],
        q: <string>'',
        loading: <boolean>false,
    }),
    actions: {
        async getAirports() {
            // if (!this.q && this.data && this.data.length > 0) return;
            this.handleLoading()
            try {
                const response = await useCustomFetch(`/api/v1/flight/airport`, {
                    method: 'POST',
                    body: {
                        query: this.q,
                    }
                }) as ApiResponse<CityAirport[]>
                if (!response.success) return this.handleLoading()

                this.data = response.data
            } catch (e: any) {

            } finally {
                this.loading = false
            }
        },
        handleLoading() {
            this.loading = !this.loading
        },
    },
    persist: {
        storage: persistedState.localStorage
    }
})